import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ChevronDownCircle, ExternalLink } from 'lucide-react';

const NavLink = ({ href, children, external = false }) => {
    const linkVariants = {
        hover: {
            scale: 1.05,
            transition: {
                duration: 0.3,
                type: 'spring',
            },
        },
    };

    const handleClick = (event) => {
        if (!external) {
            event.preventDefault();
            const section = document.querySelector(href);
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <motion.a href={href} className="hover:text-gray-300 flex items-center justify-center" variants={linkVariants} whileHover="hover" onClick={handleClick}>
            {children}
        </motion.a>
    );
};

const Navigation = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const menuButtonVariants = {
        opened: { rotate: 180, transition: { duration: 0.8, ease: "easeInOut" } },
        closed: { rotate: 0, transition: { duration: 0.8, ease: "easeInOut" } },
    };

    const menuVariants = {
        open: {
            opacity: 1,
            height: 'auto',
            transition: {
                duration: 0.5,
                ease: 'easeInOut'
            }
        },
        closed: {
            opacity: 0,
            height: 0,
            transition: {
                duration: 0.5,
                ease: 'easeInOut'
            }
        }
    };

    return (
        <main className="bg-black p-5">
            <nav className="flex justify-between items-center text-white">
                <a href="/html/" className="font-extrabold text-xl">AT</a>
                <div className="hidden md:flex justify-center items-center flex-grow gap-5">
                    <NavLink href="#work">Work</NavLink>
                    <NavLink href="#projects">Projects</NavLink>
                    <NavLink href="#skills">Skills</NavLink>
                    <NavLink href="#credentials">Credentials</NavLink>
                </div>
                <motion.div className="flex items-center">
                    <motion.button
                        className="md:hidden flex items-center"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        whileTap={{ scale: 0.95 }}
                    >
                        <motion.span variants={menuButtonVariants} animate={isMenuOpen ? "opened" : "closed"}>
                            <ChevronDownCircle />
                        </motion.span>
                    </motion.button>
                </motion.div>
            </nav>
            <motion.div
                className="flex flex-col mt-4 space-y-4 text-white md:hidden items-center"
                initial="closed"
                animate={isMenuOpen ? "open" : "closed"}
                variants={menuVariants}
            >
                <NavLink href="#work">Work</NavLink>
                <NavLink href="#projects">Projects</NavLink>
                <NavLink href="#skills">Skills</NavLink>
                <NavLink href="#credentials">Credentials</NavLink>
                {/* <NavLink href="/html/" external={true}>
                    CV <ExternalLink className="ml-1" />
                </NavLink> */}
            </motion.div>
        </main>
    );
};

export default Navigation;
