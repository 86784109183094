import React from 'react';
import { motion } from 'framer-motion';

const iconVariants = {
    initial: { scale: 1 },
    hover: { scale: 1.1, transition: { type: 'spring', stiffness: 300 } }
};

const Xicon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 256 256" className="fill-current text-gray-300">
        {/* SVG content */}
        <g fill="#c3c3c3">
            <g transform="scale(8.53333, 8.53333)">
                <path d="M26.37,26l-8.795,-12.822l0.015,0.012l7.93,-9.19h-2.65l-6.46,7.48l-5.13,-7.48h-6.95l8.211,11.971l-0.001,-0.001l-8.66,10.03h2.65l7.182,-8.322l5.708,8.322zM10.23,6l12.34,18h-2.1l-12.35,-18z"></path>
            </g>
        </g>
    </svg>
);

const GithubIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 256 256" className="fill-current text-gray-300">
        {/* SVG content */}
        <g fill="#c3c3c3">
            <g transform="scale(8.53333, 8.53333)">
            <path d="M15,3c-6.627,0 -12,5.373 -12,12c0,5.623 3.872,10.328 9.092,11.63c-0.056,-0.162 -0.092,-0.35 -0.092,-0.583v-2.051c-0.487,0 -1.303,0 -1.508,0c-0.821,0 -1.551,-0.353 -1.905,-1.009c-0.393,-0.729 -0.461,-1.844 -1.435,-2.526c-0.289,-0.227 -0.069,-0.486 0.264,-0.451c0.615,0.174 1.125,0.596 1.605,1.222c0.478,0.627 0.703,0.769 1.596,0.769c0.433,0 1.081,-0.025 1.691,-0.121c0.328,-0.833 0.895,-1.6 1.588,-1.962c-3.996,-0.411 -5.903,-2.399 -5.903,-5.098c0,-1.162 0.495,-2.286 1.336,-3.233c-0.276,-0.94 -0.623,-2.857 0.106,-3.587c1.798,0 2.885,1.166 3.146,1.481c0.896,-0.307 1.88,-0.481 2.914,-0.481c1.036,0 2.024,0.174 2.922,0.483c0.258,-0.313 1.346,-1.483 3.148,-1.483c0.732,0.731 0.381,2.656 0.102,3.594c0.836,0.945 1.328,2.066 1.328,3.226c0,2.697 -1.904,4.684 -5.894,5.097c1.098,0.573 1.899,2.183 1.899,3.396v2.734c0,0.104 -0.023,0.179 -0.035,0.268c4.676,-1.639 8.035,-6.079 8.035,-11.315c0,-6.627 -5.373,-12 -12,-12z"></path>
            </g>
        </g>
    </svg>
);

const LinkedinIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 256 256" className="fill-current text-gray-300">
        {/* SVG content */}
        <g fill="#c3c3c3">
            <g transform="scale(8.53333, 8.53333)">
            <path d="M24,4h-18c-1.105,0 -2,0.895 -2,2v18c0,1.105 0.895,2 2,2h18c1.105,0 2,-0.895 2,-2v-18c0,-1.105 -0.895,-2 -2,-2zM10.954,22h-2.95v-9.492h2.95zM9.449,11.151c-0.951,0 -1.72,-0.771 -1.72,-1.72c0,-0.949 0.77,-1.719 1.72,-1.719c0.948,0 1.719,0.771 1.719,1.719c0,0.949 -0.771,1.72 -1.719,1.72zM22.004,22h-2.948v-4.616c0,-1.101 -0.02,-2.517 -1.533,-2.517c-1.535,0 -1.771,1.199 -1.771,2.437v4.696h-2.948v-9.492h2.83v1.297h0.04c0.394,-0.746 1.356,-1.533 2.791,-1.533c2.987,0 3.539,1.966 3.539,4.522z"></path>
            </g>
        </g>
    </svg>
);



const Footer = () => {
    return (
        <div className="bg-black py-6">
            <div className="container mx-auto px-4 text-center">
                <div className="flex justify-center space-x-4 mb-4">
                    
                    <a href="https://twitter.com/anirudhtulasi" target="_blank" rel="noopener noreferrer">
                        <motion.div variants={iconVariants} initial="initial" whileHover="hover">
                            <Xicon />
                        </motion.div>
                    </a>
                    <a href="https://github.com/anirudhtulasi" target="_blank" rel="noopener noreferrer">
                        <motion.div variants={iconVariants} initial="initial" whileHover="hover">
                            <GithubIcon />
                        </motion.div>
                    </a>
                    <a href="https://linkedin.com/in/anirudhtulasi" target="_blank" rel="noopener noreferrer">
                        <motion.div variants={iconVariants} initial="initial" whileHover="hover">
                            <LinkedinIcon />
                        </motion.div>
                    </a>
                </div>
                <p className="text-sm text-gray-400">
                    Anirudh Tulasi  | {new Date().getFullYear()}
                </p>
            </div>
        </div>
    );
};

export default Footer;
