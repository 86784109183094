// Work.js
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRightCircle } from 'lucide-react';
import work1Image from '../images/work/work1.png';
import work2Image from '../images/work/work2.png';


const workItems = [
    {
        id: 1,
        company: "CancerMoonShot",
        role: "Machine Learning Developer",
        imageUrl: work1Image,
        description: [
            "Designed and optimized automated cancer detection modules using the MONAI framework for prostate, head and neck cancer, and 3D body segmentation with MRI images as input.",
            "Transformed cancer detection visualization by analyzing and incorporating 3D modeling techniques, enabling viewing on virtual reality platforms.",
            "Spearheaded cross-functional efforts to seamlessly integrate cancer detection modules into clinical workflow, resulting in improved patient outcomes and streamlined diagnostic processes.",
            "Refined model accuracy and robustness by training and validating against large data sets, achieving a increase in performance and generalization on previously unseen data."
        ],
    },
    {
        id: 2,
        company: "Deeplearning.ai",
        role: "Global Ambassador",
       imageUrl: work2Image,
        description: [
            "Successfully orchestrated community events, talks, and programs focused on deep learning and machine learning, enhancing knowledge sharing and collaboration.",
            "Founded a community and established strategic partnerships, including a notable collaboration offer from NVIDIA Emerging chapters, which expanded our reach and resource availability.",
            "Demonstrated exemplary leadership skills in communicating and coordinating with community members and partners, significantly increasing community engagement and membership.",
            "Fostered an inclusive and positive community environment, underpinned by the creation of community guidelines, promoting a spirit of unity and collaboration."
        ],
        
    },
   
];

const textVariants = {
    enter: { opacity: 0, y: 20 },
    center: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
};

const imageVariants = {
    enter: { opacity: 0, y: 600 },
    center: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -600 },
};

const WorkItem = ({ item }) => (
    <motion.div
        key={item.id}
        variants={textVariants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{ duration: 0.8 }}
        className="mt-4 lg:mt-0 lg:w-1/2 text-left lg:pl-6"
    >
        <p className="text-2xl font-bold text-gray-200 mb-2">{item.company}</p>
        <p className="text-lg text-gray-400 mb-4">{item.role}</p>
        <ul className="space-y-3 text-justify">
            {item.description.map((point, index) => (
                <li key={index} className="text-base text-gray-500">
                    {point}
                </li>
            ))}
        </ul>
    </motion.div>
);

const Work = () => {
    const [current, setCurrent] = useState(0);

    const nextWorkItem = () => {
        setCurrent((prev) => (prev + 1) % workItems.length);
    };

    const currentItem = workItems[current];

    return (
        <div className="flex flex-col lg:flex-row p-6 lg:p-10 rounded-3xl shadow-2xl">
            
            <div className="relative w-full lg:w-1/2 rounded-3xl shadow-md overflow-hidden">
                <motion.div
                    variants={imageVariants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{ duration: 0.8 }}
                >
                    <img 
                        src={currentItem.imageUrl} 
                        alt={currentItem.company} 
                        className="transition duration-300 ease-in-out transform hover:scale-105 rounded-3xl w-full h-auto object-cover"
                        style={{ maxWidth: '600px', maxHeight: '400px' }}
                    />
                </motion.div>
                <button onClick={nextWorkItem} className="absolute bottom-4 right-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-full">
                    <ChevronRightCircle />
                </button>
            </div>
            <AnimatePresence mode='wait'>
                <WorkItem item={currentItem} key={currentItem.id} />
            </AnimatePresence>
        </div>
    );
};
export default Work;

