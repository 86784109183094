import React from 'react';
import { motion } from 'framer-motion';
import BlinkingDot from './BlinkingDot';
import ChatBubble from './ChatBubble'; 

const Hero = ({ logo }) => {
    return (
        <motion.main
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="flex flex-col items-center bg-black p-5"
        >
            <motion.video
                src={logo}
                alt="Anirudh Tulasi"
                loop
                muted
                autoPlay
                playsInline
                className="w-96 h-96 rounded-full"
                initial={{ y: -250 }}
                animate={{ y: -10 }}
                transition={{ type: "spring", stiffness: 100 }}
            />
            <h3 className="text-6xl font-bold text-white">Anirudh Tulasi</h3>
            <p className="text-gray-400 text-base text-center pt-5 mb-4">
  Ex-Machine Learning Developer @ CancerMoonShot | MS Computer Engineering @ University of California, Riverside
  <span className="block text-white flex items-center justify-center">
    <div className="flex items-center justify-center">
      <BlinkingDot />
      <span>Exploring opportunities in SDE, AI, and Data roles.</span>
    </div>
  </span>
</p>
            <div className="relative" style={{ height: '100px' }}> 
                <ChatBubble />
            </div>

        </motion.main>
    );
};

export default Hero;
