import React from 'react';
import { motion } from 'framer-motion';

const BlinkingDot = () => {
    const blinkAnimation = {
        opacity: [0.3, 1, 0.3],
        boxShadow: ["0 0 8px rgba(50, 215, 75, 0.7)", "0 0 15px rgba(50, 215, 75, 1)", "0 0 8px rgba(50, 215, 75, 0.7)"],
        transition: {
            duration: 1,
            repeat: Infinity,
            ease: "easeInOut"
        }
    };

    return (
        <motion.span
            style={{ backgroundColor: '#32D74B', borderRadius: '50%' }}
            className="inline-flex w-3 h-3 mr-2"
            animate={blinkAnimation}
        />
    );
};

export default BlinkingDot;
