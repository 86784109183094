import React from 'react';
// import { motion } from 'framer-motion';
import logo from './HeroMemoji.mov';
import './App.css';
import Navigation from './components/Navigation';
import Hero from './components/Hero'; 
import Work from './components/Work'; 
import Projects from './components/Projects'; 
//import Education from './components/Education'; 
import Skills from './components/Skills'; 
import Credentials from './components/Credentials'; 
import Footer from './components/Footer';


function App() {
  return (
    <div className="App">
      <Navigation />
      <Hero logo={logo} />
    
      <main className="bg-black p-5">
        <section id="work">
          <h2 className="text-4xl font-bold text-gray-200 mb-6 text-start pl-6">Work</h2>
          <Work />
        </section>

        <section id="projects">
          <h2 className="text-4xl font-bold text-gray-200 mb-6 text-start pl-6">Projects</h2>
          <Projects />
        </section>
      </main>

      <section id="skills">
        <h2 className="text-4xl font-bold text-gray-200 mb-6 text-start pl-6">Skills</h2>
        <Skills />
      </section>

      <section id="credentials">
        <h2 className="text-4xl font-bold text-gray-200 mb-6 text-start pl-6">Credentials</h2>
        <Credentials />
      </section>

      <Footer />
    </div>
  );
}


export default App;
