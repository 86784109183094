// Projects.js
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRightCircle } from 'lucide-react';
import project1Image from '../images/projects/project1.webp';
import project2Image from '../images/projects/project2.webp';
import project3Image from '../images/projects/project3.webp';
import project4Image from '../images/projects/project4.webp';
const projectItems = [
    {
        id: 1,
        title: "G-Wave",
        subtitle: "Real-Time Earthquake Visualization & Prediction", 
        description: "G-Wave is a earthquake prediction and visualization platform that utilizes over a century's worth of seismic data to forecast potential seismic activities.",
        techStack: ["Spark", "MLlib", "Flask", "Three.js"],
        imageUrl: project1Image,
        listItems: [
            "Leveraged Apache Spark and MLlib to analyze over 3GB of historical seismic data, enhancing prediction accuracy.",
            "Real-time 3D visualization of seismic activities using Flask and Three.js, significantly improving data interpretation for end users.",
            "Initiated the transition of G-Wave to an open-source model to foster community engagement in seismic research.",
        ],
    },
    
    {
        id: 2,
        title: "TwiCov",
        subtitle: "COVID-19 Social Media Surveillance", 
        description: "TwiCov is an advanced analytics tool designed to track and analyze COVID-19 related discussions on Twitter/X, providing real-time insights into public sentiment and emerging trends.",
        techStack: ["Python", "BERT", "PyLucene", "FAISS", "Tweepy", "SNS Scraper", "JavaScript", "Flask", "React", "Tailwind"],
        imageUrl: project2Image,
        listItems: [
            "Crafted a comprehensive real-time monitoring system to capture and analyze COVID-19 mentions across major social platforms, utilizing cutting-edge technologies like BERT and PyLucene.",
            "Designed an efficient data gathering engine using Tweepy and SNS Scraper, ensuring timely collection of relevant social media posts.",
            "Implemented a highly accurate search and analysis system by integrating BERT models with Lucene Indexing, elevating the standard for health-related social media analytics.",
        ],
    },
    {
        id: 3,
        title: "DocumateAI",
        subtitle: "Local Document Interaction", 
        description: "DocumateAI offers users a seamless, efficient way to interact with digital documents on Apple Silicon Macs.",
        techStack: ["Local Chat Technology", "Large Language Models", "Vision Capabilities", "Real-Time Analysis", "Multi-Language Support", "Model Fine-Tuning"],
        imageUrl: project3Image,
        listItems: [
            "Working on a local chat interface for dynamic interaction with PDFs and digital documents, prioritizing user privacy and operational efficiency.",
            "Integrated with the latest open-sourced large language models to provide comprehensive document insights and query capabilities, all processed locally.",
            "Developing next-generation features, including image-based chat and support for varied document formats, to broaden application use cases.",
            "Enabled real-time, multi-language document analysis and interaction, ensuring DocumateAI's appeal to a global user base.",
        ],
    },
    {
        id: 4,
        title: "TWiP",
        subtitle: "Personality Insights via Twitter", 
        description: "TWiP offers a revolutionary approach to personality analysis by classifying Twitter users into Myers-Briggs types using NLP techniques.",
        techStack: ["Pandas", "pickleshare", "numpy", "scikit-learn", "nltk", "Naive Bayes Classifier", "N-grams", "Word Vectors"],
        imageUrl: project4Image,
        listItems: [
            "Developed an algorithm to analyze and classify Twitter profiles into 16 distinct personality types, leveraging a dataset of over 750K+ tweets.",
            "Utilized advanced NLP techniques, including n-grams and word vectors, combined with a Naive Bayes Classifier for high-accuracy personality predictions.",
            "The project required just the Twitter handle to generate personality insights, offering a user-friendly and accessible tool for social media analytics.",
            "Evaluated various machine learning models to optimize classification accuracy.",
        ],
    },
    
    
];

const textVariants = {
    enter: { opacity: 0, y: 20 },
    center: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
};

const buttonVariants = {
    hover: {
        scale: 1.1,
        transition: { duration: 0.2, ease: 'easeInOut' },
    },
};

const imageVariants = {
    enter: { opacity: 0, y: 600 },
    center: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -600 },
};

const ProjectItem = ({ item }) => (
    <motion.div
        key={item.id}
        variants={textVariants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{ duration: 0.8, ease: 'easeInOut' }}
        className="mt-4 lg:mt-0 lg:w-1/2 text-left lg:pl-6 pr-6"
        style={{ minHeight: '550px' }} // Set a fixed minimum height for the largest project item
    >
        <p className="text-2xl font-bold text-white mb-2">{item.title}</p>
        <p className="text-lg text-gray-400 mb-1">{item.subtitle}</p>
        <p className="text-lg text-gray-500 mb-4 text-justify">{item.description}</p>
        <ul className="list-disc list-inside mb-4 text-gray-500 text-justify">
            {item.listItems.map((listItem, index) => (
                <li key={index}>{listItem}</li>
            ))}
        </ul>
        <div className="flex flex-wrap gap-2">
            {item.techStack.map((tech, index) => (
                <motion.span
                    key={index}
                    className="bg-gray-800 hover:bg-gray-700 text-white font-medium py-1 px-2 rounded text-sm cursor-pointer mb-3"
                    variants={buttonVariants}
                    whileHover="hover"
                >
                    {tech}
                </motion.span>
            ))}
        </div>
    </motion.div>
);

const Projects = () => {
    const [current, setCurrent] = useState(0);

    const nextProjectItem = () => {
        setCurrent((prev) => (prev + 1) % projectItems.length);
    };

    const currentItem = projectItems[current];

    return (
        <div className="flex flex-col lg:flex-row p-6 lg:p-10 rounded-3xl shadow-xl bg-black">
            <AnimatePresence mode='wait'>
                <ProjectItem item={currentItem} key={currentItem.id} />
            </AnimatePresence>
            
            <div className="relative w-full lg:w-1/2 rounded-3xl overflow-hidden" style={{ maxHeight: '400px' }}>
                <AnimatePresence>
                    <motion.img
                        key={currentItem.imageUrl}
                        src={currentItem.imageUrl}
                        variants={imageVariants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        transition={{ duration: 0.8 }}
                        className="rounded-3xl w-full h-auto object-cover"
                    />
                </AnimatePresence>
                <motion.button
                    onClick={nextProjectItem}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="absolute bottom-4 right-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-full"
                >
                    <ChevronRightCircle />
                </motion.button>
            </div>
        </div>
    );
};

export default Projects;