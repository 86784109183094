import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { ExternalLink, Award, User } from 'lucide-react';

const credentials = [
    { 
        id: 1, 
        title: 'Machine Learning', 
        keywords: ['Python', 'Machine Learning (ML) Algorithms', 'Artificial Neural Network'],
        issuer: 'Coursera',
        instructor: 'Stanford University',
        link: 'https://coursera.org/share/b49d7feda8aaae75af508872e22fbedf'
    },
    { 
        id: 2, 
        title: 'DeepLearning.AI TensorFlow Developer', 
        keywords: ['Computer Vision', 'Convolutional Neural Network', 'Machine Learning', 'Natural Language Processing'],
        issuer: 'Coursera',
        instructor: 'Deeplearning.AI',
        link: 'https://coursera.org/share/c5aba3f3efcd703d927444c99c00c81b'
    },
    { 
        id: 3, 
        title: 'AI for Medicine', 
        keywords: ['Image Segmentation', 'Machine Learning', 'Natural language extraction', 'time-to-event modeling', 'Model Interpretation'],
        issuer: 'Coursera',
        instructor: 'Deeplearning.AI',
        link: 'https://coursera.org/share/43970ec05935325265ac5683c55b1d90'
    },
    // { 
    //     id: 4, 
    //     title: 'Hadoop Platform and Application Framework', 
    //     keywords: ['Python Programming', 'Apache Hadoop', 'Mapreduce', 'Apache Spark'],
    //     issuer: 'Coursera',
    //     instructor: 'University of California, San Diego',
    //     link: 'https://coursera.org/share/97eb46940fbdc3ad25d565253f44e3a1'
    // },
   
];

const credentialCardVariants = {
    offscreen: { opacity: 0, scale: 0.95 },
    onscreen: {
        opacity: 1,
        scale: 1,
        transition: { type: "spring", stiffness: 260, damping: 20 }
    },
};

const Credentials = () => {
    const containerRef = useRef(null);
    const [justifyContent, setJustifyContent] = useState('justify-start');

    useEffect(() => {
        const updateJustification = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const contentWidth = containerRef.current.scrollWidth;
                
                // Center content only if the content width is less than the container width
                setJustifyContent(contentWidth <= containerWidth ? 'justify-center' : 'justify-start');
            }
        };

        // Update justification when component mounts and on window resize
        updateJustification();
        window.addEventListener('resize', updateJustification);

        return () => window.removeEventListener('resize', updateJustification);
    }, []);

    return (
        <motion.div 
            ref={containerRef}
            className={`credentials-container overflow-x-auto scrollbar-hide p-4 flex snap-x snap-mandatory ${justifyContent}`}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
        >
            {credentials.map(({ id, title, keywords, issuer, instructor, link }) => (
                <motion.div 
                    key={id}
                    className="min-w-[280px] max-w-xs md:max-w-sm bg-gray-800 rounded-2xl p-6 flex-shrink-0 shadow-3xl snap-center relative m-2"
                    variants={credentialCardVariants}
                    whileHover={{ scale: 1.05, boxShadow: '0 10px 15px rgba(0, 0, 0, 0.3)', transition: { duration: 0.3 } }}
                >
                    <motion.a href={link} target="_blank" rel="noopener noreferrer" 
                        className="absolute top-4 right-4 text-blue-400 hover:text-blue-300 transition duration-150 ease-in-out"
                        whileHover={{ scale: 1.2 }}
                    >
                        <ExternalLink size={20}/>
                    </motion.a>

                    <div className="flex flex-col justify-between h-full">
                        <h4 className="text-xl font-semibold text-gray-200 mt-4 mb-4">
                            {title}
                        </h4>
                        
                        <div className="text-gray-400 text-sm mb-4">
                            <p><Award className="inline-block mr-2" size={16}/>Issuer: <span className="text-blue-400">{issuer}</span></p>
                            <p><User className="inline-block mr-2" size={16}/>Instructor: <span className="text-blue-400">{instructor}</span></p>
                        </div>

                        <div className="flex flex-wrap gap-2 justify-center">
                            {keywords.map((keyword, index) => (
                                <span key={index} className="text-xs bg-gray-700 rounded-full px-2 py-1 text-gray-300">
                                    {keyword}
                                </span>
                            ))}
                        </div>
                    </div>
                </motion.div>
            ))}
        </motion.div>
    );
};

export default Credentials;