import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const sentences = [
  "Eager to explore opportunities in AI/ML, data analytics, data engineering, and data science, bringing fresh perspectives and innovative solutions to forward-thinking teams.",
  "AI Applications in Healthcare: My contributions to the Cancer Moonshot project have enhanced cancer detection processes, leveraging advanced machine learning techniques for improved efficiency.",
  "DeepLearning.AI ambassador, connecting tech minds: I've built vibrant communities by sharing knowledge and fostering collaboration.",
  "Leveraging data for insights: As a graduate with hands-on experience, I'm adept at transforming data into meaningful narratives that inform decision-making and strategy development.",
  "Driving change with AI: Created G-Wave for earthquake probability prediction and TwiCov for COVID-19 analysis, showcasing my dedication to leveraging technology for societal good.",
  "Skilled in Python, Java, C++, and AI frameworks like TensorFlow and PyTorch, plus cloud (AWS, Azure), big data (Spark, Hadoop), and web tech (React, Tailwind), I tackle technical challenges head-on.",
  "Leadership in tech: Spearheaded projects and partnerships that significantly increased community engagement and resource allocation.",
  "Committed to user experience: Enhancing interfaces with my skills in Google UI/UX, React, and Tailwind, ensuring seamless end-user interactions."
];


const typingDelay = 100;
const pauseAfterTyping = 3000; // Pause after a sentence is fully typed

const ChatBubble = () => {
  const [index, setIndex] = React.useState(0);
  const [text, setText] = React.useState('');
  const isTyping = React.useRef(false);

  React.useEffect(() => {
    let charIndex = 0;
    setText(''); // Clear text for the new sentence
    isTyping.current = true;

    const typeChar = () => {
      if (charIndex < sentences[index].length) {
        setText(sentences[index].slice(0, charIndex + 1));
        charIndex++;
        setTimeout(typeChar, typingDelay);
      } else {
        isTyping.current = false;
        // Wait for a while after typing is finished before starting the next sentence
        setTimeout(() => {
          setIndex((prevIndex) => (prevIndex + 1) % sentences.length);
        }, pauseAfterTyping);
      }
    };

    typeChar();

    // Cleanup function to reset the typing status
    return () => {
      isTyping.current = false;
    };
  }, [index]);

  return (
    <div className="max-w-auto mx-auto">
      <AnimatePresence mode='wait'>
        <motion.div
          key={index}
          initial={{ y: '100%', opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: '-100%', opacity: 0 }}
          transition={{ duration: 0.69 }}
          className="p-4 rounded-full bg-blue-500 text-white my-2"
        >
          {text}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default ChatBubble;
