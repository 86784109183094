import React from 'react';
import { motion } from 'framer-motion';

const skills = [
    { 
        id: 1, 
        imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original-wordmark.svg",
        title: 'Python' 
    },
    { 
        id: 2, 
        imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original-wordmark.svg",
        title: 'Java' 
    },
    { 
        id: 3, 
        imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/c/c-plain.svg",
        title: 'C++' 
    },
    { 
        id: 4, 
        imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/matlab/matlab-original.svg",
        title: 'MATLAB' 
    },
    // { 
    //     id: 5, 
    //     imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/scala/scala-original.svg",
    //     title: 'Scala' 
    // },
    { 
        id: 6, 
        imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
        title: 'JavaScript' 
    },
    // { 
    //     id: 7, 
    //     imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg",
    //     title: 'TypeScript' 
    // },
    { 
        id: 8, 
        imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original-wordmark.svg",
        title: 'React' 
    },
    { 
        id: 9, 
        imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg",
        title: 'HTML5' 
    },
    { 
        id: 10, 
        imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg",
        title: 'CSS3' 
    },
    { 
        id: 11, 
        // imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tailwindcss/tailwindcss-plain.svg",
        // 
        title: 'TailWindCSS' 
    },
    // { 
    //     id: 12, 
    //     imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-plain-wordmark.svg",
    //     title: 'Node.js' 
    // },
    { 
        id: 13, 
        imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flask/flask-original-wordmark.svg",
        title: 'Flask' 
    },
    { 
        id: 14, 
        imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/django/django-plain-wordmark.svg",
        title: 'Django' 
    },
    { 
        id: 15, 
        imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tensorflow/tensorflow-original.svg",
        title: 'TensorFlow' 
    },
    { 
        id: 16, 
        // imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/django/django-plain-wordmark.svg",
        title: 'Keras' 
    },
    { 
        id: 17, 
        // imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/django/django-plain-wordmark.svg",
        title: 'Scikit-Learn' 
    },
    { 
        id: 18, 
        // imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/pytorch/pytorch-original.svg",
        title: 'PyTorch' 
    },
    { 
        id: 19, 
        // imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/django/django-plain-wordmark.svg",
        title: 'MLlib' 
    },
    { 
        id: 20, 
        imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/pandas/pandas-original.svg",
        title: 'Pandas' 
    },
    { 
        id: 21, 
        imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/numpy/numpy-original.svg",
        title: 'NumPy' 
    },
    // { 
    //     id: 22, 
    //     imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/d3js/d3js-original.svg",
    //     title: 'D3.js' 
    // },
    { 
        id: 23, 
        // imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/django/django-plain-wordmark.svg",
        title: 'Matplotlib' 
    },
    { 
        id: 24, 
        // imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/django/django-plain-wordmark.svg",
        title: 'Seaborn' 
    },
    { 
        id: 25, 
        // imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/django/django-plain-wordmark.svg",
        title: 'PowerBI' 
    },
    { 
        id: 26, 
        imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/apache/apache-original-wordmark.svg",
        title: 'Apache Hadoop' 
    },
    { 
        id: 27, 
        imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/apache/apache-original-wordmark.svg",
        title: 'Apache Spark' 
    },
    // { 
    //     id: 28, 
    //     // imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/amazonwebservices/amazonwebservices-original.svg",
    //     // 
    //     title: 'AWS' 
    // },
    { 
        id: 29, 
        imageUrl: " https://cdn.jsdelivr.net/gh/devicons/devicon/icons/azure/azure-original.svg",
        title: 'Azure' 
    },
    { 
        id: 30, 
        imageUrl: "  https://cdn.jsdelivr.net/gh/devicons/devicon/icons/googlecloud/googlecloud-original.svg",
        title: 'Google Cloud Platform (GCP)' 
    },
    { 
        id: 31, 
        imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-plain-wordmark.svg",
        title: 'Docker' 
    },
    // { 
    //     id: 32, 
    //     imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/kubernetes/kubernetes-plain.svg",
    //     title: 'Kubernetes' 
    // },
    { 
        id: 33, 
        title: 'CI/CD Tools' 
    },
    { 
        id: 34, 
        imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original-wordmark.svg",
        title: 'SQL' 
    },
    { 
        id: 35, 
        imageUrl: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jupyter/jupyter-original-wordmark.svg",
        title: 'Jupyter Notebooks' 
    },
  


   
];

// Function to filter skills by ID for different lines
const filterSkills = (ids) => skills.filter(skill => ids.includes(skill.id));

// IDs for each line
const line1Ids = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
const line2Ids = [15,16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27];
const line3Ids = [28, 29, 30, 31, 32, 33, 34];

// Filtered skills for each line
const line1Skills = filterSkills(line1Ids);
const line2Skills = filterSkills(line2Ids);
const line3Skills = filterSkills(line3Ids);

const calculateTotalWidth = (skills) => skills.length * 40 * 4; // Increased duplication

const marqueeVariants = (direction, totalWidth) => ({
    animate: {
        x: direction === 'left' ? [-totalWidth, 0] : [0, -totalWidth],
        transition: {
            x: {
                repeat: Infinity,
                repeatType: "loop",
                duration: 60, // Increased duration for smoother transition
                ease: "linear",
            },
        },
    },
});

const SkillsLine = ({ skills, direction }) => {
    const totalWidth = calculateTotalWidth(skills);
    const skillsContent = Array(4).fill(skills).flat();

    return (
        <div className="relative w-full ">
            <motion.ul
                className="flex gap-2 skills-line "
                variants={marqueeVariants(direction, totalWidth)}
                animate="animate"
            >
                {skillsContent.map((skill, index) => (
                    <motion.li
                        key={index}
                        className="flex items-center justify-center bg-gray-800 rounded-lg p-2 min-w-max mb-5 "
                        whileHover={{ 
                            scale: 1.1, 
                            transition: { duration: 0.3, type: 'spring' } 
                        }}
                    >
                        <span className="text-white text-sm mr-2">#{skill.title}</span>
                        {skill.imageUrl && (
                            <img src={skill.imageUrl} alt={skill.title} className="w-6 h-6 object-contain" />
                        )}
                    </motion.li>
                ))}
            </motion.ul>
            <div className="absolute top-0 left-0 w-16 h-full bg-gradient-to-r from-gray-900 to-transparent"></div>
            <div className="absolute top-0 right-0 w-16 h-full bg-gradient-to-l from-gray-900 to-transparent"></div>
        </div>
    );
};


const Skills = () => {
    return (
        <div className="skills-container overflow-hidden relative w-full my-4 ">
            <SkillsLine skills={line1Skills} direction="left" />
            <SkillsLine skills={line2Skills} direction="right" />
            <SkillsLine skills={line3Skills} direction="left" />
        </div>
    );
};

export default Skills;